import RouterComponent from "./RouterComponent";
import { BrowserRouter } from "react-router-dom";
import { AuthContextProvider } from "./Context/auth-context";
import LoginPage from "./Pages/LoginPage/LoginPage";
import PiDashboardPage from "./Pages/DashboardPages/PipeDashboard/PiDashboardPage";
import { useContext, useState } from "react";
import { UserContext } from "./Context/UserContext";

function App() {
  const [user,setUser]=useState((localStorage.getItem("token")))
  const signin=()=>{
    const password='PIUSER'
    setUser(true)
    localStorage.setItem('pi',password)
  }
  const signout=()=>{
    setUser(false)
    localStorage.removeItem('pi')
  }
  
  console.log(user)
  return (
    <AuthContextProvider>
     <UserContext.Provider value={{user,setUser,signin,signout}}>
      <div className="App">
        {/* <BrowserRouter>
          <RouterComponent></RouterComponent>
        </BrowserRouter> */}
        <BrowserRouter>
        {!user?<LoginPage/>:<PiDashboardPage/>}
        </BrowserRouter>
      </div>
      </UserContext.Provider> 
    </AuthContextProvider>
  );
}

export default App;
