import React, { Fragment, useEffect, useState } from "react";
import styles from "./VehicalAndPipeCount.module.css";

export const options_machineUtilization = {
  chartArea: { width: "50%", height: "75%", top: 5 },
  backgroundColor: "transparent",
  legend: "none",
};

const VehicalAndPipeCount = (props) => {
  // console.log( props.tables)

  return (
    <Fragment>
      <div align="center">
        <h5>Vehical and total pipes</h5>
      </div>

      <div style={{ height: "300px", overflow: "scroll" }}>
        <table
          className={"table " + styles.table}
          style={{ maxHeight: "100px" }}
        >
          <thead>
            <tr>
              <td>ID</td>
              <td>Truck</td>
              <td>Total Pipes</td>
            </tr>
          </thead>
          <tbody>
            {props.tables.map((table, index) => (
              
              
                <tr key={index}>
                <td>{table.id}</td>
                <td>{`Truck${index + 1}`}</td>
                <td>{table.Total_Pipes_per_Vehicle}</td>
              </tr>))
            
              
            } 
          </tbody>
        </table>
      </div>
    </Fragment>
  );
};

export default VehicalAndPipeCount;
