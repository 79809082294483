import { Fragment, React, useContext } from "react";

import profile from "./Images/profile.jpg";
import logo from "./Images/logo.jpg";

import styles from "./TopBarStyle.module.css";
import AuthContext from "../../../../../Context/auth-context";
import { UserContext } from "../../../../../Context/UserContext";

const TopBar = () => {
  const ctx = useContext(AuthContext);
  const {setUser,signout}=useContext(UserContext)
  return (
    <Fragment>
      {/* <div className={"col-3 " + styles.serchfield}>
        <input type="text" placeholder="Search"></input>
        <i className="fa-solid fa-magnifying-glass"></i>
      </div> */}
      <div className="col-3"></div>

      <div className="col-3"></div>

      <div className={"col-2 mt-1 " + styles.topbaricons}>
        <i className="fa fa-solid fa-bell ml-4"></i>
        <i className="fa fa-solid fa-envelope ml-4"></i>
        <i className="fa fa-solid fa-gear ml-4"></i>
      </div>

      <div className={"col-1 p-1 " + styles.filters}>
        <span>Filter </span>
        <i className="fa-solid fa-filter ml-1"></i>
      </div>

      <div
        className={"col-0.8 ml-2 d-flex"}
        style={{ border: "1px solid #000", padding: "0 5px" }}
      >
        <img
          className={styles.profileimage}
          width="32px"
          height="32px"
          alt="profilepic"
          src={profile}
          style={{ marginRight: "10px" }}
        />
        <img width="32px" height="32px" alt="profilepic" src={logo} />
      </div>
      <div
        className={"pl-3 pr-3 pt-1 " + styles.logoutbtn}
        // onClick={ctx.logout}
        onClick={()=>{
          setUser(false);
          signout()
        }}
      >
        Logout
      </div>
    </Fragment>
  );
};

export default TopBar;
