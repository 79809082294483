import { Fragment, React, useEffect, useState } from "react";

import Modal from "../../../Components/UIComponents/Modal/Modal";

import SideBar from "./Components/SideBar/SideBar";
import TopBar from "./Components/TopBar/TopBar";
import Reports from "./Components/Reports/Reports";
import MainContent from "./Components/Dashboard/MainContent";
import styles from "./DashboardStyle.module.css";
import DashboardPages from "./DashboardConstants";
import { apiRoutes } from "../../../api/apiRoutes";
import axios from "axios";

const PiDashboardPage = () => {
    async function detectCircle(){
    window.alert("Detection Started")
        const res=await axios('https://cd7429dc50b7.ngrok.app/detect_circles')

      }
     



  const [currentDashboardPage, setDashboardState] = useState(
    DashboardPages.DashboardOverview
  );

  const ChangeDashboardState = (state) => {
    setDashboardState(state);
  };
  return (
    <Fragment>
      <Modal></Modal>
      <div className={styles.maindashboardbody}>
        <div className="row">
          <div className="col-2 border-right-5" id={styles.sidemenu}>
            <SideBar
              currentActive={currentDashboardPage}
              onClick={ChangeDashboardState}
            ></SideBar>
          </div>

          <div className="col-10" style={{ padding: 0 }}>
            <div className="row pt-1 pl-4 mt-1">
              <TopBar></TopBar>
            </div>

            <hr />

            <div className="row mt-2">
              <div className={"col " + styles.MainContentContainer}>
                {currentDashboardPage === DashboardPages.DashboardOverview && (
                  <MainContent detectCircle={detectCircle}></MainContent>
                )}
                {currentDashboardPage !== DashboardPages.DashboardOverview &&
                  currentDashboardPage !== DashboardPages.FloorBoard && (
                    <Reports tableRequest={currentDashboardPage}></Reports>
                  )}
                {currentDashboardPage === DashboardPages.FloorBoard && (
                  <div>Page Under Construction</div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default PiDashboardPage;
