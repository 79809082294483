import React, { Fragment } from "react";
import emissionImg from "./images/co2.png";
import treesImg from "./images/trees.png";

const EnvironmentalBenifits = (props) => {
  return (
    <Fragment>
      <h5>
        <strong>Environmental benefits (static data)</strong>
      </h5>

      <div className="row">
        <div className="col pl-3 mt-3">
          <div style={{ display: "flex" }}>
            <img src={emissionImg} alt="emissionImg" width={100} />
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
              }}
            >
              <h6>Co2 Emmission saved</h6>
              <strong>7696636 KG</strong>
            </div>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col pl-3" style={{ display: "flex" }}>
          <img src={treesImg} alt="treesImg" width={100} />
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
            }}
          >
            <h6>Equivalent Trees planted</h6>
            <strong>22972</strong>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default EnvironmentalBenifits;
