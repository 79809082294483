import React, { useState, useRef, useContext } from "react";
import styles from "./LoginFormStyle.module.css";
import { useNavigate } from "react-router-dom";
import AuthContext from "../../../../Context/auth-context";
import PagesPath from "../../../../Constants/PagesPath";
import axios from "axios";
import {UserContext} from "../../../../Context/UserContext";

import eyeopen from "./Images/eyeopen.png";
import eyeclose from "./Images/eyeclose.png";

const LoginForm = () => {
  const ctx = useContext(AuthContext);
  const _passwordInputRef = useRef();
  const _navigate = useNavigate();
  const {setUser,signin}=useContext(UserContext)

  const [_isPasswordInvalid, setIsPasswordInvalid] = useState(false);
  const [_InvalidPasswordMessage, setPasswordInvalidMessage] = useState(
    "Password Cannot be empty"
  );
  const [_isPasswordTouched, setIsPasswordTouched] = useState(false);
  const [showPassword, setPasswordState] = useState(false);

  const [credentials, setCredentials] = useState({
    username: "",
    password: "",
  });

  const passwordInputInvalid = _isPasswordTouched && _isPasswordInvalid;

  const passwordStateHandler = () => {
    setPasswordState((prevState) => {
      return prevState ? false : true;
    });
  };

  const onPasswordBlur = (e) => {
    setIsPasswordTouched(true);

    _passwordInputRef.current.value
      ? setIsPasswordInvalid(false)
      : setIsPasswordInvalid(true);

    setPasswordInvalidMessage("Password Cannot be empty !");
  };

  const OnFormSubmit = (event) => {
    event.preventDefault();

    setIsPasswordTouched(true);

    if (!_passwordInputRef.current.value) {
      setIsPasswordInvalid(true);
      setPasswordInvalidMessage("Password Cannot be empty !");
      return;
    }

    // TryLoginUser(_passwordInputRef.current.value);
    TryLoginUser(_passwordInputRef.current.value);
  };

  const TryLoginUser = async (password) => {
    if (
      password !== "CNCUSER" &&
      password !== "TEXUSER" &&
      password !== "STAMPUSER" &&
      password !== "PIUSER"
    ) {
      setIsPasswordInvalid(true);
      setPasswordInvalidMessage("Password is incorrect !");
      return;
    }

    signin()
    setUser(true)

    const data = { token: "token", UserType: credentials.password };
    console.log(data);
    ctx.login(data);
    _navigate(PagesPath.DashboardPage, { replace: true });
  };

  return (
    <form onSubmit={OnFormSubmit}>
      {/* <div className={styles.inputPassword + " " + styles.ElementSize}>
        <input
          type="text"
          placeholder="Email"
          onChange={(e) =>
            setCredentials({ ...credentials, username: e.target.value })
          }
        ></input>
      </div> */}
      <br></br>

      <div
        className={
          "col-12 " +
          styles.inputPassword +
          " " +
          styles.ElementSize +
          " " +
          (passwordInputInvalid && styles.passwordError)
        }
      >
        <input
          type={showPassword ? "text" : "password"}
          onBlur={onPasswordBlur}
          placeholder="Password"
          ref={_passwordInputRef}
          onChange={(e) =>
            setCredentials({ ...credentials, password: e.target.value })
          }
        ></input>
        {/* <img
          src={showPassword ? eyeopen : eyeclose}
          onClick={passwordStateHandler}
          width="20px"
          alt="eyeicon"
        ></img> */}
      </div>

      {passwordInputInvalid && (
        <div className={styles.errorShown}>
          <span>{_InvalidPasswordMessage}</span>
        </div>
      )}

      {/* <div className={"mt-2 " + styles.rememberMeSection}>
        <label>
          <input type="checkbox" className={styles.styledCheckbox} />
          <span> Remember me ?</span>
        </label>
        <a href="/" style={{ color: "black" }}>
          {" "}
          Forgot password
        </a>
      </div> */}
      <br />
      <button
        type="submit"
        className={"mt-4 " + styles.ElementSize + " " + styles.button}
      >
        LOGIN
      </button>
    </form>
  );
};

export default LoginForm;
