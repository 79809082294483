import { React, useEffect, useState } from "react";
import Cards from "../../../../../Components/UIComponents/Card/Cards";
import OverView from "./OverView/OverView";
import LiveFootageCard from "./LiveFootageCard/LiveFootageCard";
import VehicalAndPipeCount from "./VehicalAndPipeCount/VehicalAndPipeCount";
import EnvironmentalBenifits from "./EnvironmentalBenifits/EnvironmentalBenifits";
import VehicalTimeForWeight from "./VehicalTimeForWeight/VehicalTimeForWeight";
import axios from "axios";
import { apiRoutes } from "../../../../../api/apiRoutes";

//Data Section
export const data_pichart = [
  ["Task", "Hours per Day"],
  ["Missing parts", 30],
  ["Service", 30],
  ["Broken machine", 40],
];
export const data_machineUtilization = [
  [
    "Machine",
    "Utlilization",
    { role: "style" },
    {
      sourceColumn: 0,
      role: "annotation",
      type: "string",
      calc: "stringify",
    },
  ],
  ["Machine 1", 5, "grey", 5],
  ["Machine 2", 4, "grey", 4],
  ["Machine 3", 5.3, "grey", 5.3],
  ["Machine 4", 4.3, "grey", 4.3],
  ["Machine 5", 6, "grey", 6],
];
export const data_machine1 = [
  ["h1", "Density", { role: "style" }],
  ["h2", 8.94, "black"], // RGB value
  ["h3", 10.49, "red"], // English color name
  ["h4", 19.3, "black"],
  ["h5", 21.45, "red"],
  ["h5", 10, "black"],
  ["h5", 15, "red"],
  ["h5", 16, "black"], // CSS-style declaration
];
export const data_linechart = [
  ["time", "Throughput"],
  ["1", 100],
  ["1:30", 200],
  ["2", 150],
  ["2:30", 300],
  ["3", 350],
  ["3:30", 200],
  ["4", 100],
];
export const data_sharecapicity = [
  ["m1", "capacity", { role: "style" }],
  ["m2", 8.94, "black"], // RGB value
  ["m3", 10.49, "black"], // English color name
  ["m4", 19.3, "black"],
  ["m5", 21.45, "black"],
  ["m6", 10, "black"],
  ["m7", 15, "black"],
  ["m8", 16, "black"], // CSS-style declaration
];
//Data Section Ends

const MainContent = (props) => {
  const [tables, setTables] = useState([]);

  useEffect(() => {
    async function getTables() {
      const res = await axios.get('https://cd7429dc50b7.ngrok.app/tables/?skip=0&limit=5');
      setTables(res.data);
    }
    getTables();
  }, []);

  



  return (
    <div className="col">
      <div className="row pr-4">
        <Cards
          className={"col-5"}
          style={{
            display: "flex !important",
            flexDirection: "column !important",
            justifyContent: "center !important",
          }}
        >
          <OverView tables={tables}></OverView>
        </Cards>

        <Cards className={"col ml-1"}>
          <LiveFootageCard data={data_pichart} detectCircle={props.detectCircle}></LiveFootageCard>
        </Cards>
      </div>

      <div className="row mt-1 pr-4">
        <Cards className={"col-8"}>
          <VehicalAndPipeCount tables={tables}></VehicalAndPipeCount>
        </Cards>

        {/* <Cards className={"col ml-1"}>
          <VehicalTimeForWeight></VehicalTimeForWeight>
        </Cards> */}

        <Cards className={"col ml-1"}>
          <EnvironmentalBenifits></EnvironmentalBenifits>
        </Cards>
      </div>
    </div>
  );
};

export default MainContent;
