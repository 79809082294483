import { Fragment, React, useState } from "react";
import styles from "./SideBarStyle.module.css";
import DashboardPages from "../../DashboardConstants";

import pilogo from "./images/Pi.png";

const SideBar = (props) => {
  const [showList, setListState] = useState(false);

  const currentActive = props.currentActive;
  const ExpandList = () => {
    Navigate(DashboardPages.Reports.CCTVS);
    setListState((prevState) => {
      // console.log(prevState);
      return prevState ? false : true;
    });
  };

  const Navigate = (page) => {
    props.onClick(page);
  };

  return (
    <Fragment>
      <div className="row mt-3 pt-1 justify-content-center">
        <img src={pilogo} alt={"logo"} width="150px" />
      </div>
      <div className="row mt-4 justify-content-left pr-2">
        <ul id={styles.sideMenuItems}>
          <li
            onClick={() => Navigate(DashboardPages.DashboardOverview)}
            className={
              currentActive === DashboardPages.DashboardOverview
                ? styles.pageselected
                : ""
            }
          >
            <span>
              <i className={"fa fa-thin fa-house-user"}></i>{" "}
            </span>
            Dashboard
          </li>
          <li>
            <span onClick={ExpandList}>
              <i className="fa fa-thin fa-chart-column"></i> Reports{" "}
              <i className="fa-solid fa-angle-down"></i>
            </span>
            <ul
              className={
                showList ? styles.ReportsListShown : styles.ReportsListHidden
              }
            >
              {" "}
              {/*Commenteed will uncomment when finished*/}
              {Object.keys(DashboardPages.Reports).map((data, id) => {
                return (
                  <li
                    key={id}
                    onClick={() => Navigate(DashboardPages.Reports[data])}
                    className={
                      currentActive === DashboardPages.Reports[data]
                        ? styles.pageselected
                        : ""
                    }
                  >
                    {DashboardPages.Reports[data]}
                  </li>
                );
              })}
            </ul>
          </li>
          {/* <li
            onClick={() => Navigate(DashboardPages.FloorBoard)}
            className={
              currentActive === DashboardPages.FloorBoard
                ? styles.pageselected
                : ""
            }
          >
            <span>
              <i className="fa-solid fa-border-none"></i>{" "}
            </span>
            Floor board
          </li> */}
        </ul>
      </div>
    </Fragment>
  );
};

export default SideBar;
