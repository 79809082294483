export function apiRoutes(pageNumber){
    const detectCircleAPI='https://66cbe1055074.ngrok.app:8000/detect_circles'
    const videoFeedAPI='https://66cbe1055074.ngrok.app:8000/video_feed'
    const insertDataAPI='https://66cbe1055074.ngrok.app:8000/insert_data'
    const tableAPI='https://66cbe1055074.ngrok.app:8000/tables/?skip=0&limit=5'
    const dataAPI=`https://66cbe1055074.ngrok.app:8000/data/${pageNumber}`
    const downloadReportAPI='https://66cbe1055074.ngrok.app:8000/download_report'
    const releaseAPI='https://66cbe1055074.ngrok.app:8000/release'
    const flagAPI='https://66cbe1055074.ngrok.app:8000/flag'

    // const detectCircleAPI='http://localhost:8000/detect_circles'
    // const videoFeedAPI='http://localhost:8000/video_feed'
    // const insertDataAPI='http://localhost:8000/insert_data'
    // const tableAPI='http://localhost:8000/tables/?skip=0&limit=5'
    // const dataAPI=`http://localhost:8000/data/${pageNumber}`
    // const downloadReportAPI='http://localhost:8000/download_report'
    // const releaseAPI='http://localhost:8000/release'
    // const flagAPI='http://localhost:8000/flag'

    return {detectCircleAPI,videoFeedAPI,insertDataAPI,tableAPI,dataAPI,downloadReportAPI,releaseAPI,flagAPI}
}