import { React } from 'react'

import LoginForm from './Components/LoginFormComponent/LoginForm'
import styles from './LoginPageStyle.module.css'

import logoImage from './Images/logo.png'
import lockimage from './Images/Lock.png'

const LoginPage = () => {
    return (
        <div className="container-fluid">
            <div className={"row " + styles.verticalCenter}>
                <LeftPage></LeftPage>
                <RightPage></RightPage>
            </div>
        </div >
    )
}

const LeftPage = () => {
    return (<div className={"col-md-6 " + styles.verticalCenter + " " + styles.leftchild} >
        <div className={'row justify-content-center ' + styles.logo} >
            <div className='col-12'>
                <img src={logoImage} width="200px" alt='Logo' />
            </div>
            <div className='col-12 mt-2'>
                <strong><span className={styles.redtext}>"</span>Building world's best computer vision products<span className={styles.redtext}>"</span></strong>
            </div>
        </div>
    </div>);
}

const RightPage = () => {
    return (
        <div className={"col-md-6 " + styles.verticalCenter + " " + styles.rightchild} >
            <div align="center" className={styles.loginForm}>
                <div className={'col-12 mb-3'}>
                    <img src={lockimage} width="100px" alt='lockimage' />
                </div>
                <LoginForm></LoginForm>
            </div>
        </div>
    )
}

export default LoginPage